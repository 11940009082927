import React from "react";

const Paragraph = (props) => {
    return (
        <div className="boxii">
            <h1 style={{color:"#005aa3"}}>{props.title}</h1>
            {props.paragraph?
                <p>{props.paragraph}</p>:<></>
            }
            <ul>
                {props.list.map((e,i)=><li key={i}>{e}</li>)}
            </ul>
        </div>
    )
}

export default Paragraph