import React from "react";
import Logo from '../assets/images/Logo-stallions.png';

const Header = () => {
    return (
        <header>
            <img src={Logo} alt="LOGO" />
        </header>
    )
}

export default Header