import React from "react";

const Footer = () => {
    return (
        <footer>
            Conatct Us: info@stallionslimited.net
        </footer>
    )
}

export default Footer