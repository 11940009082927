// import logo from './logo.svg';
import './App.css';
import Header from "./components/header";
import Truck from "./assets/images/stall-99.jpg";
import Paragraph from "./components/paragraph";
import Footer from "./components/footer";

function App() {
  return (
    <>
      <Header />
      <img style={{width:"100%"}} src={Truck} alt="Truck" />
      <Paragraph title="Company Profile" paragraph="Saudi Arabia is the largest economy in Middleast and one of the world. A country that has been marking over 8 percent growth and mainly an import market, has taken in recent years remarkable steps in consumer safety and their protection. Inline to this objective, the Saudi government has created and adopted number of global standards for the product certifications.
        Stallions is specialized in offering services to companies exporting products to Saudi Arabia or offering services related to export to or import to Saudi Arabia. Our partners and staff have an extensive experience with the Saudi import regulation and development. The partners have been engaged in such regulation projects for over 20 years.
        We offer our services to limited customer, to make sure we offer them correct attention, as the scope of services require constant engagement with authorities and clients. Our Goal is to provide our best services with in Legal frame work of Saudi Arabia only.
        Our services include following" list={[
        "Standards and importing requirement for specific product category or type",
        "Consulting to our clients on dealing with the Saudi Approved Certification Bodies",
        "Representing our customers legally in front of authorities",
        "Working as a bridge of information and understanding between Saudi Authorities and our clients"
        ]}
      />
      <Paragraph title="Clients Sectors" list={[
        "Logistic & Good Transportation Companies",
        "Construction material Importers",
        "Certification Companies",
        "Spare part Importers"
        ]}
      />
      <Paragraph title="Stallion Services" list={[
        "Consulting to our clients on dealing with the Saudi Arabian Standard Organisation program (SALEEM), for exporting goods to Saudi Arabia",
        "Representing our customers to the authorities, pertaining their issues for documents regarding importation",
        "Working as a bridge of information and understanding between Saudi Authorities and our clients"
        ]}
      />
      <Footer />
    </>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
